/* Site CSS Variables  */

:root {
  --main-bg-color: #296f50;
  --button-color: #df8e30;
  --gray-color: rgba(185, 185, 185);
  --transparent-white-color: rgba(255, 255, 255, 0);
}

body {
  font-family: "Karla", sans-serif;
  font-weight: 400;
}

.boldWeight {
  font-weight: 800;
}

#logoNavbar {
  width: 30px;
}

/* font sizing using rem and clamp method  */
/* Clamp command: 375px min screen, 1980px max screen, and assume 1 rem=16px */
/* font-size: clamp( min-scrn-fontsize, yAxisIntersection[rem] + (slope * 100)[vw], max-scrn-fontsize); */

h1 {
  font-size: clamp(2.5rem, 2.4626rem + 1.4953vw, 4rem);

  /* font-size: clamp(3rem, 2.9701rem + 0.9969vw, 4rem); */

  /* font-size: clamp(3rem, 2.9103rem + 2.9907vw, 6rem); */
  /* line-height: 0.84; */
  line-height: 1.1;
  padding-bottom: 20px;
  font-weight: 400;
}
h2 {
  font-size: clamp(2rem, 1.9801rem + 0.9969vw, 3rem);

  /* font-size: clamp(2rem, 1.9701rem + 1.4953vw, 3.5rem); */
  line-height: 1.3;
  font-weight: 400;
  /* padding-bottom: 20px;  */
}
.h3Plus {
  font-size: clamp(1.75rem, 1.7369rem + 0.7477vw, 2.5rem);

  /* font-size: clamp(1.75rem, 1.7282rem + 1.2461vw, 3rem); */
  line-height: 1.17;
  font-weight: 400;
}
h3,
.navbarStyle {
  font-size: clamp(1.5rem, 1.4963rem + 0.2492vw, 1.75rem);

  /* font-size: clamp(1.5rem, 1.4925rem + 0.4984vw, 2rem); */
  line-height: 1.17;
  font-weight: 400;
}
.navbarStyle {
  color: #296f50 !important;
}

.navbarStyleLinks {
  color: #296f50 !important;
  padding-top: 8px;
}

.navbarDropStyle {
  font-size: clamp(1.4rem, 1.3951rem + 0.3489vw, 1.75rem);
  color: #296f50 !important;
}
h4 {
  font-size: clamp(1rem, 0.995rem + 0.4984vw, 1.5rem);

  /* font-size: clamp(1.4rem, 1.3951rem + 0.3489vw, 1.75rem); */
  line-height: 1.17;
  font-weight: 400;
}
.pPlus {
  font-size: clamp(1rem, 0.995rem + 0.4984vw, 1.5rem);
  /* font-size: clamp(1.25rem, 1.2469rem + 0.2492vw, 1.5rem); */
  line-height: 1.17;
  font-weight: 400;
}
p {
  font-size: 1.125em;
  line-height: 1.17;
  font-weight: 400;
}
.headerSection {
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}
.pageButton {
  font-size: clamp(1rem, 0.995rem + 0.4984vw, 1.5rem);

  /* font-size: clamp(1.5rem, 1.4963rem + 0.2492vw, 1.75rem); */

  /* font-size: clamp(1.5rem, 1.4925rem + 0.4984vw, 2rem); */
  line-height: 1.17;
  font-weight: 400;
}

.calcRightCardText {
  max-width: 250px;
}

button {
  background-color: var(--button-color);
  color: white;
  border-width: 0;
  border-radius: 5px;
  padding: 14px 28px;
}

button:disabled,
button[disabled] {
  /* border: 1px solid #999999; */
  background-color: rgb(240, 242, 244);
  color: rgb(136, 138, 139);
}

.navbarButton {
  background-color: var(--button-color) !important;
  color: white;
  border-width: 0;
  /* transform: translate(0%, -25%); */
  padding: 7px 13px;
}

.headerSection {
  padding-bottom: 125px;
}

.grayFont {
  color: #8b8b8b;
}

.calculateIntroLeft {
  /* margin-bottom: 15px; */
  /* background-color: #F1F1F1; */
  background-color: var(--transparent-white-colo);
  /* background-image: linear-gradient(
    to right,
    var(--transparent-white-color),
    var(--main-bg-color)
  ); */

  position: relative;
  text-align: center;

  padding-right: 0px;
  padding-left: 0px;

  /* display: flex;
    flex-direction: row;
    align-items: center; */
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.centerTextInImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .calculateIntroLeft h2 {
    text-align: center !important;
    
} */

.calculateIntroRight {
  background-color: var(--main-bg-color);
  border-width: 0;
  color: white;
  /* padding-top: 125px;
    padding-bottom: 125px; */
  width: 100%;
  /* margin-bottom: 15px; */

  display: flex;
  flex-direction: row;
  align-items: center;
}

.verticalCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calculateIntroRight .card {
  background-color: var(--main-bg-color);
  border-width: 0;
  color: white;
  max-width: 475px;
}

.calculateIntroRight h2 {
  text-align: center;
}

hr {
  height: 1px;
  background-color: white;
  border: none;
}

.windTurbineIllustration {
  box-shadow: 2vw 2vw var(--main-bg-color);
  width: 95%;
  vertical-align: middle;
  margin-top: 20px;
}
.windTurbineIllustrationLogin {
  box-shadow: 1vw 1vw var(--main-bg-color);
  width: 98%;
  vertical-align: middle;
  margin-top: 20px;
}

footer {
  text-align: center;
  color: white;
  margin-left: 15px;
  margin-right: 15px;
  padding-right: 1em;
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: var(--main-bg-color);
}

.testCSS {
  text-align: center;
}

.scamText {
  text-align: center;
  font-family: 'Rock Salt', cursive;
}

.processCard {
  /* max-width: 300px; */
  /* height: 300px; */
  /* padding: 10px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* margin-inline: 20px; */
  border-radius: 10%;
  /* text-align: center; */
  /* filter: drop-shadow(0px 16px 3px #666666); */
  /* box-shadow: 0px 16px 35px -2px rgba(0, 0, 0, 0.4); */

  margin-left: auto;
  margin-right: auto;
  max-width: 372px;
  /* height: 400px; */
  /* margin-inline: 20px; */
  text-align: center;
  /* filter: drop-shadow(0px 16px 3px #666666); */
  box-shadow: 0px 16px 35px -2px rgba(0, 0, 0, 0.4);
}

.processCard img {
  width: 126px;
  margin: auto;
}

.processCard h4 {
  /* margin-top: -15px; */
}

.processCard p {
  margin-top: -10px;
}

.missionCard {
  margin-left: auto;
  margin-right: auto;
  max-width: 372px;
  /* height: 400px; */
  /* margin-inline: 20px; */
  text-align: center;
  /* filter: drop-shadow(0px 16px 3px #666666); */
  box-shadow: 0px 16px 35px -2px rgba(0, 0, 0, 0.4);
}

.missionCard img {
  width: 85px;
  margin: auto;
}

footer a {
  color: white;
}

footer a:hover {
  color: white;
}

/* The Info Button */
#infoButton {
  color: var(--main-bg-color);
  float: right;
  position: relative;
  top: 10px;
  left: 10px;
  font-size: 20px;
  margin-left: 0px;
}

#infoButton:hover,
#infoButton:focus {
  filter: brightness(85%);
  text-decoration: none;
  cursor: pointer;
}

/* The Modal Close Button */
#close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

#close:hover,
#close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23DF8E30' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23DF8E30' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

/* .carousel .carousel-indicators li {
  background-color: #c4c4c4;
}
.carousel .carousel-indicators li.active {
  background-color: var(--main-bg-color);
} */

.carousel-indicators li {
  border-top: unset;
  width: 10px;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: var(--gray-color);
  height: 15px;
  width: 15px;
  margin-inline: 5px;
}

.carousel-indicators .active {
  border-top: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: var(--main-bg-color);
  margin-inline: 5px;
  margin-top: -3px;
  height: 20px;
  width: 20px;
}

.calculatorCards {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 21rem !important;
}

.calculatorCardImage {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
}

/* Modification of carousel cards for smaller screens (phone)  */
@media only screen and (max-width: 550px) {
  #carouselCardsSection {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Modification of offsets table for smaller screens (phone)  */
@media only screen and (max-width: 400px) {
  #offsetsTable {
    padding-left: 5px;
    /* padding-right: 0px; */
  }
}

.aboutImg {
  /* height: 500px; */
  height: 95%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Modification of register image for screens >992  */
@media only screen and (min-width: 992px) {
  .registerImg {
    /* height: 500px; */
    height: 95%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 992px) {
  .registerImg {
    width: 98%;
    /* transform: rotate(-90deg); */
    /* width: 200px; */
    /* height: 300px; */
    /* object-fit: contain; */
  }
}

.calculatorTitle {
  text-align: center;
}

.calculatorCardsBackground {
  background: #d8d8d8;
  border-radius: 10px;
}

/* FAQ Accordian */
.accordion .card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.challengeParagraph {
  margin-left: 90px;
}

@media only screen and (max-width: 425px) {
  .challengeParagraph {
    /* position: absolute; */
    margin-left: 20px;
  }
}

.challengeHeading {
  overflow: hidden;
}
